import a from './1.png';
import b from './2.png';
import c from './3.png';
import d from './4.png';
import e from './5.png';
import f from './6.png';
import g from './7.png';
import h from './8.png';
import i from './9.png';
import j from './10.png';
import k from './11.png';
import l from './12.png';
import m from './13.png';
import n from './14.png';
import o from './15.png';
// import p from './16.png';
import q from './17.png';
import r from './18.png';
import s from './19.png';
// import t from './20.png';
import u from './21.png';
import v from './22.png';
import w from './23.png';
import x from './24.png';
import y from './25.png';
import z from './26.png';
import aa from './27.png';

// const images = { a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, aa };
const images = { a, b, c, d, e, f, g, h, i, j, k, l, m, n, o,  q, r, s,  u, v, w, x, y, z, aa };


export default images